const publications = [
  {
    year: 2024,
    items: [
      {
        image: "",
        subHeader: "",
        header: "NeurDB: On the Design and Implementation of an AI-powered Autonomous Database",
        authors:
          "Zhanhao Zhao, Shaofeng Cai, Haotian Gao, Hexiang Pan, Siqi Xiang, Naili Xing, Gang Chen, Beng Chin Ooi, Yanyan Shen, Yuncheng Wu, Meihui Zhang",
        action: "Read Paper",
        link: "https://arxiv.org/pdf/2408.03013",
      },
      {
        image: "",
        subHeader: "",
        header: "NeurDB: An AI-powered Autonomous Data System",
        authors:
          "Beng Chin Ooi, Shaofeng Cai, Gang Chen, Kian-Lee Tan, Yuncheng Wu, Xiaokui Xiao, Naili Xing, Cong Yue, Lingze Zeng, Meihui Zhang, Zhanhao Zhao",
        action: "Read Paper",
        link: "https://arxiv.org/pdf/2405.03924",
      },
      {
        image: "",
        subHeader: "",
        header:
          "Powering In-Database Dynamic Model Slicing for Structured Data Analytics",
        authors:
          "Lingze Zeng, Naili Xing, Shaofeng Cai, Gang Chen, Beng Chin Ooi, Jian Pei, Yuncheng Wu",
        action: "Read Paper",
        link: "https://arxiv.org/pdf/2405.00568",
      },
      {
        image: "",
        subHeader: "Proc. VLDB Endow., 17(5):1020–1033, 2024",
        header:
          "Database native model selection: Harnessing deep neural networks in database systems",
        authors:
          "Naili Xing, Shaofeng Cai, Gang Chen, Zhaojing Luo, Beng Chin Ooi, and Jian Pei",
        action: "Read Paper",
        link: "https://www.vldb.org/pvldb/vol17/p1020-xing.pdf",
      },
      {
        image: "",
        subHeader: "Proc. VLDB Endow., 2024",
        header:
          "Secure and verifiable data collaboration with low-cost zero-knowledge proofs",
        authors:
          "Yizheng Zhu, Yuncheng Wu, Zhaojing Luo, Beng Chin Ooi, and Xiaokui Xiao",
        action: "Read Paper",
        link: "https://www.vldb.org/pvldb/vol17/p2321-xiao.pdf",
      },
      {
        image: "",
        subHeader: "Proc. ACM Manag. Data, 1(4):270:1-270:27, 2024",
        header:
          "VeriTxn: Verifiable transactions for cloud-native databases with storage disaggregation",
        authors:
          "Zhanhao Zhao, Hexiang Pan, Gang Chen, Xiaoyong Du, Wei Lu, and Beng Chin Ooi",
        action: "Read Paper",
        link: "https://dl.acm.org/doi/10.1145/3626764",
      },
    ],
  },
  {
    year: 2023,
    items: [
      {
        image: "",
        subHeader: "Proc. VLDB Endow., 16(6):1359-1371, 2023",
        header:
          "GlassDB: An efficient verifiable ledger database system through transparency",
        authors:
          "Cong Yue, Tien Tuan Anh Dinh, Zhongle Xie, Meihui Zhang, Gang Chen, Beng Chin Ooi, and Xiaokui Xiao",
        action: "Read Paper",
        link: "https://www.vldb.org/pvldb/vol16/p1359-ooi.pdf",
      },
      {
        image: "",
        subHeader: "Proc. VLDB Endow., 16(10):2471-2484, 2023",
        header:
          "Falcon: A privacy-preserving and interpretable vertical federated learning system",
        authors:
          "Yuncheng Wu, Naili Xing, Gang Chen, Tien Tuan Anh Dinh, Zhaojing Luo, Beng Chin Ooi, Xiaokui Xiao, and Meihui Zhang",
        action: "Read Paper",
        link: "https://www.vldb.org/pvldb/vol16/p2471-ooi.pdf",
      },
      {
        image: "",
        subHeader: "Proc. ACM Manag. Data, 1(2):158:1-158:27, 2023",
        header: "Incentive-aware decentralized data collaboration",
        authors:
          "Yatong Wang, Yuncheng Wu, Xincheng Chen, Gang Feng, and Beng Chin Ooi",
        action: "Read Paper",
        link: "https://dl.acm.org/doi/abs/10.1145/3589303",
      },
    ],
  },
  {
    year: 2021,
    items: [
      {
        image: "",
        subHeader: "In ACM Multimedia, pages 1293-1302. ACM, 2021",
        header: "Singa-easy: An easy-to-use framework for multimodal analysis",
        authors:
          "Naili Xing, Sai Ho Yeung, Chenghao Cai, Teck Khim Ng, Wei Wang, Kaiyuan Yang, Nan Yang, Meihui Zhang, Gang Chen, and Beng Chin Ooi",
        action: "Read Paper",
        link: "https://www.comp.nus.edu.sg/~ooibc/SINGAEasy.pdf",
      },
    ],
  },
  {
    year: 2019,
    items: [
      {
        image: "",
        subHeader: "Proc. VLDB Endow., 13(2):86-99, 2019",
        header:
          "Model slicing for supporting complex analytics with elastic inference cost and resource constraints",
        authors: "Shaofeng Cai, Gang Chen, Beng Chin Ooi, and Jinyang Gao",
        action: "Read Paper",
        link: "https://www.vldb.org/pvldb/vol13/p86-cai.pdf",
      },
    ],
  },
  {
    year: 2018,
    items: [
      {
        image: "",
        subHeader: "Proc. VLDB Endow., 11(10):1137-1150, 2018",
        header:
          "ForkBase: An efficient storage engine for blockchain and forkable applications",
        authors:
          "Sheng Wang, Tien Tuan Anh Dinh, Qian Lin, Zhongle Xie, Meihui Zhang, Qingchao Cai, Gang Chen, Beng Chin Ooi, and Pingcheng Ruan",
        action: "Read Paper",
        link: "https://www.vldb.org/pvldb/vol11/p1137-wang.pdf",
      },
    ],
  },
  {
    year: 2016,
    items: [
      {
        image: "",
        subHeader: "SIGMOD Rec., 45(2):17-22, 2016",
        header: "Database meets deep learning: Challenges and opportunities",
        authors:
          "Wei Wang, Meihui Zhang, Gang Chen, H. V. Jagadish, Beng Chin Ooi, and Kian-Lee Tan",
        action: "Read Paper",
        link: "https://www.comp.nus.edu.sg/~ooibc/dbdl.pdf",
      },
    ],
  },
  {
    year: 2015,
    items: [
      {
        image: "",
        subHeader: "In ACM Multimedia, pages 25-34. ACM, 2015",
        header: "SINGA: putting deep learning in the hands of multimedia users",
        authors:
          "Wei Wang, Gang Chen, Tien Tuan Anh Dinh, Jinyang Gao, Beng Chin Ooi, Kian-Lee Tan, and Sheng Wang",
        action: "Read Paper",
        link: "https://www.comp.nus.edu.sg/~ooibc/singa-mm15.pdf",
      },
      {
        image: "",
        subHeader: "In ACM Multimedia, pages 685-688. ACM, 2015",
        header: "SINGA: A distributed deep learning platform",
        authors:
          "Beng Chin Ooi, Kian-Lee Tan, Sheng Wang, Wei Wang, Qingchao Cai, Gang Chen, Jinyang Gao, Zhaojing Luo, Anthony K. H. Tung, Yuan Wang, Zhongle Xie, Meihui Zhang, and Kaiping Zheng",
        action: "Read Paper",
        link: "https://www.comp.nus.edu.sg/~ooibc/singaopen-mm15.pdf",
      },
    ],
  },
];

export default publications;
